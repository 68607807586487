<template>
  <import-table
    :fileHeaders="fileHeaders"
    :rows="rows"
    :fields="fields"
    :items="trips"
    :type="'Trips'"
    :statusMap="statusMap"
    :fileStatuses="fileStatuses"
    :importing="importing"
    @mapValues="mapValues"
    @import="importTrips"
    ref="importTableRef"
  >
  </import-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, parse, isValid } from 'date-fns';
import { uniqBy } from 'lodash';
import importApi from '@/apis/import';
import ImportTable from './ImportTable.vue';

export default {
  label: 'TripImportTable',
  inject: ['eventHub'],
  components: { ImportTable },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportations', 'additionalTransportationsByName']),
    ...mapGetters('budgetCode', ['budgetCodes']),
    ...mapGetters('destination', ['destinations', 'destinationsByName']),
    ...mapGetters('fiscalYear', ['fiscalYears']),
    ...mapGetters('fundingSource', ['fundingSources', 'fundingSourcesByName']),
    ...mapGetters('location', ['locations', 'locationsByName']),
    ...mapGetters('semester', ['semesters']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesByName']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsByName']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesByName']),
    fields() {
      return [
        // { label: 'ID', value: 'id', mappedTo: null, validations: [] },
        { label: 'Local ID', value: 'localId', mappedTo: null, type: 'text', validations: [] },
        {
          label: 'Semester*',
          value: 'semesterId',
          mappedTo: null,
          type: { items: this.semesters },
          validations: ['validateRequired'],
        },
        {
          label: 'Fiscal Year*',
          value: 'fiscalYearId',
          mappedTo: null,
          type: { items: this.fiscalYears },
          validations: ['validateRequired'],
        },
        { label: 'Batch ID', value: 'batchId', mappedTo: null, type: 'text', validations: [] },
        {
          label: 'Category*',
          value: 'category',
          mappedTo: null,
          type: {
            items: [
              { id: 1, name: 'Travel with Students' },
              { id: 2, name: 'Staff Only' },
            ],
          },
          validations: ['validateRequired'],
        },
        {
          label: 'Trip Type*',
          value: 'tripTypeId',
          mappedTo: null,
          type: { items: this.tripTypes },
          validations: ['validateRequired'],
        },
        {
          label: 'Trip Event(s)*',
          value: 'tripEventId',
          mappedTo: null,
          type: { items: this.tripEvents },
          validations: ['validateRequired'],
        },
        {
          label: 'Location*',
          value: 'locationId',
          mappedTo: null,
          type: { items: this.locations },
          validations: ['validateRequired'],
        },
        { label: 'Need Substitute', value: 'needSubstitute', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Out of County', value: 'outOfCounty', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Out of State', value: 'outOfState', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Comments', value: 'comments', mappedTo: null, type: 'text', validations: [] },
        { label: 'One Way', value: 'oneOrRound', mappedTo: null, type: 'boolean', validations: [] },
        {
          label: 'Leave Date*',
          value: 'leaveDate',
          mappedTo: null,
          type: 'date',
          validations: ['validateRequired'],
        },
        {
          label: 'Leave Time*',
          value: 'leaveTime',
          mappedTo: null,
          type: 'time',
          validations: ['validateRequired'],
        },
        {
          label: 'Return Date*',
          value: 'returnDate',
          mappedTo: null,
          type: 'date',
          validations: ['validateRequired'],
        },
        {
          label: 'Return Time*',
          value: 'returnTime',
          mappedTo: null,
          type: 'time',
          validations: ['validateRequired'],
        },
        {
          label: 'Destination*',
          value: 'destinationId',
          mappedTo: null,
          type: { items: this.destinations },
          validations: ['validateRequired'],
        },
        {
          label: 'Instructions for Permission Slip',
          value: 'instructionsForPermissionSlip',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        { label: 'Teacher Name', value: 'teacherName', mappedTo: null, type: 'text', validations: [] },
        { label: 'Teacher Email', value: 'teacherEmail', mappedTo: null, type: 'text', validations: [] },
        { label: 'Teacher Phone', value: 'teacherPhone', mappedTo: null, type: 'text', validations: [] },
        {
          label: 'Teacher is Emergency Contact?',
          value: 'emergencySameAsTeacher',
          type: 'boolean',
          mappedTo: null,
          validations: [],
        },
        {
          label: 'Emergency Contact Name',
          value: 'emergencyContactName',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        {
          label: 'Emergency Contact Phone',
          value: 'emergencyContactPhone',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        {
          label: '# Students*',
          value: 'totalStudents',
          mappedTo: null,
          type: 'number',
          validations: ['validateRequired'],
        },
        {
          label: '# Adults*',
          value: 'totalAdults',
          mappedTo: null,
          type: 'number',
          validations: ['validateRequired'],
        },
        {
          label: '# Special Needs Students',
          value: 'numSpecialNeedsStudents',
          mappedTo: null,
          type: 'number',
          validations: [],
        },
        // { label: 'Adults by Gender', value: 'adultsByGender', mappedTo: null, type: 'boolean', validations: [] },
        // { label: 'Students by Gender', value: 'studentsByGender', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Group Description', value: 'groupDescription', mappedTo: null, type: 'text', validations: [] },
        { label: 'Grade Levels', value: 'gradeLevels', mappedTo: null, type: 'text', validations: [] },
        // { label: 'Have Health Concerns', value: 'haveHealthConcerns', mappedTo: null, validations: [] },
        { label: 'Health Concerns', value: 'healthConcerns', mappedTo: null, type: 'text', validations: [] },
        // { label: 'Away For Lunch', value: 'awayForLunch', mappedTo: null, validations: [] },
        { label: 'Need Lunch', value: 'needLunch', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Food Accommodations', value: 'foodAccomodations', mappedTo: null, type: 'text', validations: [] },
        // { label: 'Need External Transportation', value: 'needExternalTransportation', mappedTo: null, validations: [] },
        {
          label: 'Ext Transportation Details',
          value: 'extTransportationDetail',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        {
          label: 'Need District Vehicles',
          value: 'needDistrictVehicles',
          mappedTo: null,
          type: 'boolean',
          validations: [],
        },
        {
          label: 'Need Driver Assigned',
          value: 'needDriverAssigned',
          mappedTo: null,
          type: 'boolean',
          validations: [],
        },
        {
          label: 'Vehicle Type',
          value: 'vehicleTypeId',
          mappedTo: null,
          type: { items: this.vehicleTypes },
          validations: [],
        },
        {
          label: 'Additional Transportation',
          value: 'additionalTransportationId',
          mappedTo: null,
          type: { items: this.additionalTransportations },
          validations: [],
        },
        { label: '# Vehicles', value: 'numVehicles', mappedTo: null, type: 'number', validations: [] },
        { label: 'Dropoff Only', value: 'dropoffOnly', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Pickup Only', value: 'pickupOnly', mappedTo: null, type: 'boolean', validations: [] },
        { label: 'Vehicle Location', value: 'vehLocation', mappedTo: null, type: 'text', validations: [] },
        { label: 'Vehicle Pickup Date', value: 'vehPickupDate', mappedTo: null, type: 'date', validations: [] },
        { label: 'Vehicle Pickup Time', value: 'vehPickupTime', mappedTo: null, type: 'time', validations: [] },
        { label: 'Vehicle Return Date', value: 'vehReturnDate', mappedTo: null, type: 'date', validations: [] },
        { label: 'Vehicle Return Time', value: 'vehReturnTime', mappedTo: null, type: 'time', validations: [] },
        { label: 'Venue Arrive Date', value: 'venueArriveDate', mappedTo: null, type: 'date', validations: [] },
        { label: 'Venue Arrive Time', value: 'venueArriveTime', mappedTo: null, type: 'time', validations: [] },
        { label: 'Venue Depart Date', value: 'venueDepartDate', mappedTo: null, type: 'date', validations: [] },
        { label: 'Venue Depart Time', value: 'venueDepartTime', mappedTo: null, type: 'time', validations: [] },
        {
          label: 'Split Trip',
          value: 'splitTrip',
          mappedTo: null,
          type: 'boolean',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Drop-Off Leg Veh Pickup Date',
          value: 'dropOffLegVehPickupDate',
          mappedTo: null,
          type: 'date',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Drop-Off Leg Veh Pickup Time',
          value: 'dropOffLegVehPickupTime',
          mappedTo: null,
          type: 'time',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Drop-Off Leg Veh Return Date',
          value: 'dropOffLegVehReturnDate',
          mappedTo: null,
          type: 'date',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Drop-Off Leg Veh Return Time',
          value: 'dropOffLegVehReturnTime',
          mappedTo: null,
          type: 'time',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Return Leg Veh Pickup Date',
          value: 'returnLegVehPickupDate',
          mappedTo: null,
          type: 'date',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Return Leg Veh Pickup Time',
          value: 'returnLegVehPickupTime',
          mappedTo: null,
          type: 'time',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Return Leg Veh Return Date',
          value: 'returnLegVehReturnDate',
          mappedTo: null,
          type: 'date',
          validations: [],
          hide: 'split-trips',
        },
        {
          label: 'Return Leg Veh Return Time',
          value: 'returnLegVehReturnTime',
          mappedTo: null,
          type: 'time',
          validations: [],
          hide: 'split-trips',
        },
        // { label: 'Need Special Needs Vehicle', value: 'needSpecialNeedsVehicle', mappedTo: null, validations: [] },
        {
          label: '# Special Needs Vehicle',
          value: 'numSpecialNeedsVehicle',
          mappedTo: null,
          type: 'number',
          validations: [],
        },
        { label: '# Fold Down Seat', value: 'numFoldDownSeat', mappedTo: null, type: 'number', validations: [] },
        { label: '# Safety Vest', value: 'numSafetyVest', mappedTo: null, type: 'number', validations: [] },
        { label: '# Wheelchair Slot', value: 'numWheelchairSlot', mappedTo: null, type: 'number', validations: [] },
        { label: 'Special Travel Needs', value: 'specialTravelNeeds', mappedTo: null, type: 'text', validations: [] },
        { label: 'Vehicle Comments', value: 'vehComments', mappedTo: null, type: 'text', validations: [] },
        { label: 'Vehicle Driver Info', value: 'vehDriverInfo', mappedTo: null, type: 'text', validations: [] },
        {
          label: 'Funding Source 1',
          value: 'fundingSource1',
          mappedTo: null,
          type: { items: this.fundingSources },
          validations: [],
        },
        {
          label: 'Budget Code 1',
          value: 'budgetCode1',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        {
          label: 'Funding Source 2',
          value: 'fundingSource2',
          mappedTo: null,
          type: { items: this.fundingSources },
          validations: [],
        },
        {
          label: 'Budget Code 2',
          value: 'budgetCode2',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        // { label: 'Payable to Third Party', value: 'payableToThirdParty', mappedTo: null, validations: [] , hide: 'third-party-payments'},
        {
          label: 'Third Party Amount',
          value: 'thirdPartyAmount',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party Option',
          value: 'thirdPartyOption',
          mappedTo: null,
          type: [],
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party Name',
          value: 'thirdPName',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party Address',
          value: 'thirdPAddress',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party City',
          value: 'thirdPCity',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party State',
          value: 'thirdPState',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party Zip',
          value: 'thirdPZip',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'PCard Account',
          value: 'pcardAccount',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party PO',
          value: 'thirdPartyPO',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Third Party Comments',
          value: 'thirdPartyComments',
          mappedTo: null,
          type: 'text',
          validations: [],
          hide: 'third-party-payments',
        },
        {
          label: 'Educational Objective',
          value: 'educationalObjective',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        { label: 'Special Indicators', value: 'specialIndicators', mappedTo: null, type: 'text', validations: [] },
        { label: 'Comments for Driver', value: 'commentsForDriver', mappedTo: null, type: 'text', validations: [] },
        // { label: 'Has Attachment', value: 'hasAttachment', mappedTo: null, validations: [] },
        { label: 'Submitted User', value: 'submittedUser', mappedTo: null, type: 'text', validations: [] },
        { label: 'Submitted Timestamp', value: 'submittedTimestamp', mappedTo: null, type: 'number', validations: [] },
        { label: 'Submitted Date', value: 'submittedDate', mappedTo: null, type: 'date', validations: [] },
        // { label: 'User Accepted', value: 'userAccepted', mappedTo: null, validations: [] },
        { label: 'Created', value: 'created', mappedTo: null, type: 'number', validations: [] },
        { label: 'Created By', value: 'createdBy', mappedTo: null, type: 'text', validations: [] },
        // { label: 'Last Updated', value: 'lastUpdated', mappedTo: null, validations: [] },
        // { label: 'Last Updated By', value: 'lastUpdatedBy', mappedTo: null, validations: [] },
        {
          label: 'Status*',
          value: 'status',
          mappedTo: null,
          type: { items: this.statusMap },
          validations: ['validateRequired'],
          helper: () => (this.$refs.importTableRef.openStatusHelper = true),
        },
        { label: 'Approved', value: 'approved', mappedTo: null, type: 'boolean', validations: [] },
        // { label: 'Pending Approval Level', value: 'pendingApprovalLevel', mappedTo: null, validations: [] },
        {
          label: 'Assignment Location',
          value: 'assignmentLocationId',
          type: { items: this.locations },
          mappedTo: null,
          validations: [],
        },
        { label: 'Distance', value: 'distance', mappedTo: null, type: 'number', validations: [] },
        { label: 'Time', value: 'time', mappedTo: null, type: 'number', validations: [] },
        {
          label: 'Est Venue Cost Per Student',
          value: 'estVenueCostPerStudent',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est Driver Hours',
          value: 'estDriverHours',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est District Wide Rate ID',
          value: 'estDistrictWideRateId',
          mappedTo: null,
          type: [],
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est # Substitutes/Assistants',
          value: 'estNumSubstitutesAssistants',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est Other Costs',
          value: 'estOtherCosts',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est Paid By Student',
          value: 'estPaidByStudent',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
        {
          label: 'Est Total Cost',
          value: 'estTotalCost',
          mappedTo: null,
          type: 'number',
          validations: [],
          hide: 'estimates',
        },
      ];
    },
    fileStatuses() {
      const statusHeader = this.fileHeaders.find((e) => e.label.toLowerCase().trim() == 'status');
      if (statusHeader) {
        const statuses = this.rows.map((e) => ({ name: e[statusHeader.value] }));
        return uniqBy(statuses, 'name');
      }
      return [];
    },
  },
  data() {
    return {
      trips: [],
      importing: false,
      statusMap: [
        { name: 'Completed', id: 2 },
        { name: 'Submitted', id: 1 },
        { name: 'Editing/Resubmit', id: -1 },
        { name: 'Denied', id: -2 },
        { name: 'Canceled', id: -3 },
      ],
    };
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.trips.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'locationId' && typeof rowVal == 'string') {
          if (this.locationsByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.locationsByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (field.value == 'destinationId' && typeof rowVal == 'string') {
          if (this.destinationsByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.destinationsByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (field.value == 'tripTypeId' && typeof rowVal == 'string') {
          if (this.tripTypesByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.tripTypesByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (field.value == 'tripEventId' && typeof rowVal == 'string') {
          if (this.tripEventsByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.tripEventsByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (field.value == 'vehicleTypeId' && typeof rowVal == 'string') {
          if (this.vehicleTypesByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.vehicleTypesByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (field.value == 'additionalTransportationId' && typeof rowVal == 'string') {
          if (this.additionalTransportationsByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.additionalTransportationsByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if ((field.value == 'fundingSource1' || field.value == 'fundingSource2') && typeof rowVal == 'string') {
          if (this.fundingSourcesByName[rowVal.toLowerCase().trim()])
            this.trips[i][field.value] = this.fundingSourcesByName[rowVal.toLowerCase().trim()].id;
          else this.trips[i][field.value] = null;
        } else if (
          field.value == 'thirdPartyAmount' ||
          field.value == 'estVenueCostPerStudent' ||
          field.value == 'estOtherCosts' ||
          field.value == 'estPaidByStudent' ||
          field.value == 'estTotalCost'
        ) {
          this.trips[i][field.value] = Number(rowVal.replace(/[^0-9.,]+/, ''));
        } else if (field.value == 'status') {
          if (value !== undefined) this.trips[i][field.value] = rowVal;
          else {
            const s = this.fileStatuses.find((e) => e.name == rowVal);
            if (s) this.trips[i][field.value] = s.mappedTo;
          }
        } else this.trips[i][field.value] = rowVal;

        if (field.type == 'date' && !value) {
          const dt = this.trips[i][field.value];
          this.trips[i][field.value] = dt && isValid(new Date(dt)) ? format(new Date(dt), 'yyyy-MM-dd') : null;
        } else if (field.type == 'time' && !value) {
          if (this.trips[i][field.value]) {
            const dt = isValid(new Date(this.trips[i][field.value]))
              ? new Date(this.trips[i][field.value])
              : new Date('01/01/2000 ' + this.trips[i][field.value]);

            this.trips[i][field.value] = isValid(dt) ? format(dt, 'HH:mm') : null;
          }
        } else if (field.type == 'boolean')
          this.trips[i][field.value] = this.$refs.importTableRef.convertToBoolean(this.trips[i][field.value]);
      }
    },
    async importTrips() {
      this.importing = true;
      try {
        this.$refs.importTableRef.cleanText(this.trips);
        const r = await importApi.importTrips(this.trips);
        this.$myalert.success('Trips imported successfully');
      } catch (e) {
        this.$myalert.error(`Failed to import trips: ${e.message}`);
      }
      this.importing = false;
    },
  },
  watch: {
    rows() {
      this.trips = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.trips.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
};
</script>

<style scoped></style>
