<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="users"
      :importing="importing"
      @mapValues="mapValues"
      @import="importUsers"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { format, isValid } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  name: 'UserTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      users: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('user', ['roles', 'rolesByName']),
    ...mapGetters('location', ['locations', 'locationsByName']),
    fields() {
      return [
        {
          label: 'Email*',
          value: 'email',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'First Name*',
          value: 'firstName',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Last Name*',
          value: 'lastName',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Display Name',
          value: 'displayName',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Phone',
          value: 'phone',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Role',
          value: 'roleIds',
          type: {
            items: this.roles.filter((e) => e.id !== 9).map((e) => ({ text: e.name, value: e.id })),
            multiple: true,
          },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Location',
          value: 'locationIds',
          type: { items: this.locations.map((e) => ({ text: e.name, value: e.id })), multiple: true },
          validations: [],
          mappedTo: null,
        },
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.users.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'roleIds' && typeof rowVal == 'string') {
          const roles = [];
          for (let r of rowVal.split(';'))
            if (this.rolesByName[r.toLowerCase().trim()]) roles.push(this.rolesByName[r.toLowerCase().trim()].id);
          this.users[i][field.value] = roles;
        } else if (field.value == 'locationIds' && typeof rowVal == 'string') {
          const locations = [];
          for (let l of rowVal.split(';'))
            if (this.locationsByName[l.toLowerCase().trim()])
              locations.push(this.locationsByName[l.toLowerCase().trim()].id);
          this.users[i][field.value] = locations;
        } else {
          this.users[i][field.value] = rowVal;
        }

        if (field.type == 'date' && !value) {
          const dt = this.users[i][field.value];
          this.users[i][field.value] = dt && isValid(new Date(dt)) ? format(new Date(dt), 'yyyy-MM-dd') : null;
        } else if (field.type == 'boolean') {
          this.users[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        }
      }
    },
    async importUsers() {
      this.importing = true;
      const data = this.users.map((e) => {
        return {
          email: e.email,
          firstName: e.firstName,
          lastName: e.lastName,
          displayName: e.displayName || e.firstName + ' ' + e.lastName,
          phone: e.phone || null,
          roleIds: e.roleIds,
          locationIds: e.locationIds,
        };
      });
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importUsers(data);
        this.$myalert.success('Users imported successfully.');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeUsers() {
      this.users = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.users.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeUsers();
    },
  },
};
</script>

<style scoped></style>
