<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="specialDates"
      :importing="importing"
      @mapValues="mapValues"
      @import="importSpecialDates"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { format, isValid } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  name: 'SpecialDateTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      specialDates: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('tripType', ['tripTypes', 'tripTypesByName']),
    fields() {
      return [
        {
          label: 'Begin Date*',
          value: 'begin',
          type: 'date',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'End Date*',
          value: 'end',
          type: 'date',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Description',
          value: 'description',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Trip Types*',
          value: 'tripTypeIds',
          type: { items: this.tripTypes.map((e) => ({ text: e.name, value: e.id })), multiple: true },
          validations: ['validateRequired'],
          mappedTo: null,
        },
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.specialDates.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'tripTypeIds' && typeof rowVal == 'string') {
          const tripTypes = [];
          for (let tt of rowVal.split(';'))
            if (this.tripTypesByName[tt.toLowerCase().trim()])
              tripTypes.push(this.tripTypesByName[tt.toLowerCase().trim()].id);
          this.specialDates[i][field.value] = tripTypes;
        } else {
          this.specialDates[i][field.value] = rowVal;
        }

        if (field.type == 'date' && !value) {
          const dt = this.specialDates[i][field.value];
          this.specialDates[i][field.value] = dt && isValid(new Date(dt)) ? format(new Date(dt), 'yyyy-MM-dd') : null;
        } else if (field.type == 'boolean') {
          this.specialDates[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        }
      }
    },
    async importSpecialDates() {
      this.importing = true;
      const data = this.specialDates.map((e) => {
        return {
          type: 'special',
          begin: e.begin,
          end: e.end,
          description: e.description,
          tripTypeIds: e.tripTypeIds,
          tripEventIds: '',
        };
      });
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importSpecialDates(data);
        this.$myalert.success('Special Dates imported successfully.');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeSpecialDates() {
      this.specialDates = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.specialDates.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeSpecialDates();
    },
  },
};
</script>

<style scoped></style>
