<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="budgetCodes"
      :importing="importing"
      @mapValues="mapValues"
      @import="importBudgetCodes"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { format, isValid } from 'date-fns';
import { mapGetters } from 'vuex';
import { toLowerCamelCase } from '@/util';

export default {
  name: 'BudgetCodeTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      budgetCodes: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('config', ['budgetCodeConfig']),
    fields() {
      const struct = this.budgetCodeConfig.map((e) => ({
        label: `${e.name}*`,
        value: toLowerCamelCase(e.name),
        type: 'text',
        validations: ['validateRequired'],
        mappedTo: null,
      }));
      return [
        { label: 'Name*', value: 'name', type: 'text', validations: ['validateRequired'], mappedTo: null },
        ...struct,
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.budgetCodes.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        this.budgetCodes[i][field.value] = rowVal;
      }
    },
    async importBudgetCodes() {
      this.importing = true;
      const data = [];
      for (let bc of this.budgetCodes) {
        const d = { name: bc.name };
        delete bc.name;
        delete bc.errors;
        d.code = bc;
        data.push(d);
      }
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importBudgetCodes(data);
        this.$myalert.success('Budget Codes imported successfully.');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeBudgetCodes() {
      this.budgetCodes = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.budgetCodes.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeBudgetCodes();
    },
  },
};
</script>

<style scoped></style>
