<template>
  <import-table
    :fileHeaders="fileHeaders"
    :rows="rows"
    :fields="fields"
    :items="assignments"
    :importing="importing"
    @mapValues="mapValues"
    @import="importAssignments"
    ref="importTableRef"
  ></import-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, parse, isValid } from 'date-fns';
import { uniqBy } from 'lodash';
import importApi from '@/apis/import';
import ImportTable from './ImportTable.vue';

export default {
  label: 'AssignmentTable',
  inject: ['eventHub'],
  components: { ImportTable },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('driver', ['drivers']),
    ...mapGetters('location', ['locations', 'locationsByName']),
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('vehicle', ['vehicles', 'vehiclesByName']),
    tripRequestLocalIds() {
      return uniqBy(
        this.tripRequests.map((e) => ({ name: e.localId, id: e.id })),
        'name'
      );
    },
    driverItems() {
      return this.drivers.map((e) => ({ name: e.lastName + ', ' + e.firstName, id: e.id }));
    },
    fields() {
      return [
        // { label: 'ID', value: 'id', mappedTo: null, validations: [] },
        {
          label: 'Trip ID*',
          value: 'tripRequestId',
          mappedTo: null,
          type: { items: this.tripRequestLocalIds },
          validations: ['validateRequired'],
        },
        {
          label: 'Driver',
          value: 'driverId',
          mappedTo: null,
          type: { items: this.driverItems },
          validations: [],
        },
        {
          label: 'Driver Email',
          value: 'driverEmail',
          mappedTo: null,
          type: 'text',
          validations: [],
        },
        {
          label: 'Assistant',
          value: 'assistantId',
          mappedTo: null,
          type: { items: this.driverItems },
          validations: [],
        },
        {
          label: 'Vehicle',
          value: 'vehicleId',
          mappedTo: null,
          type: { items: this.vehicles },
          validations: [],
        },
        { label: 'Start Time', value: 'startTime', mappedTo: null, type: 'time', validations: [] },
        { label: 'End Time', value: 'endTime', mappedTo: null, type: 'time', validations: [] },
        {
          label: 'Start Mileage',
          value: 'startMileage',
          mappedTo: null,
          type: 'number',
          validations: [],
        },
        {
          label: 'End Mileage',
          value: 'endMileage',
          mappedTo: null,
          type: 'number',
          validations: [],
        },
        { label: 'Notes', value: 'notes', mappedTo: null, type: 'text', validations: [] },
      ];
    },
  },
  data() {
    return {
      assignments: [],
      batchEditField: {},
      batchEditValue: null,
      importing: false,
    };
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.assignments.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'tripRequestId' && typeof rowVal == 'string') {
          const t = this.tripRequests.find((e) => e.localId == rowVal);
          if (t) this.assignments[i][field.value] = t.id;
          else this.assignments[i][field.value] = null;
        } else if ((field.value == 'driverId' || field.value == 'assistantId') && typeof rowVal == 'string') {
          const ln = rowVal.split(', ')[0];
          const fn = rowVal.split(', ')[1];
          const d = this.drivers.find(
            (e) => e.lastName.toLowerCase() == ln.toLowerCase() && e.firstName.toLowerCase() == fn.toLowerCase()
          );
          if (d) {
            this.assignments[i][field.value] = d.id;
            this.assignments[i].driverEmail = d.email;
          } else this.assignments[i][field.value] = fn + ' ' + ln;
        } else if (field.value == 'vehicleId' && typeof rowVal == 'string') {
          if (this.vehiclesByName[rowVal.toLowerCase()])
            this.assignments[i][field.value] = this.vehiclesByName[rowVal.toLowerCase()].id;
          else this.assignments[i][field.value] = null;
        } else this.assignments[i][field.value] = rowVal;

        if (field.type == 'date') {
          const dt = new Date(this.assignments[i][field.value]);
          this.assignments[i][field.value] = format(dt, 'yyyy-MM-dd');
        } else if (field.type == 'time') {
          const dt = new Date(this.assignments[i][field.value]);
          this.assignments[i][field.value] = format(dt, 'k:mm');
        }
      }
    },
    async importAssignments() {
      this.importing = true;
      try {
        this.$refs.importTableRef.cleanText(this.assignments);
        const r = await importApi.importAssignments(this.assignments);
        this.$myalert.success('Assignments imported successfully');
      } catch (e) {
        this.$myalert.error(`Failed to import assignments: ${e.message}`);
      }
      this.importing = false;
    },
  },
  watch: {
    rows() {
      this.assignments = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.assignments.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}
tr {
  margin-bottom: 2px;
}
td {
  /* border: 1px solid; */
  text-align: center;
  vertical-align: middle;
}
.checkbox {
  display: flex;
  align-items: center !important;
}
.override-class {
  display: inline-block;
  width: 100px;
}
</style>
