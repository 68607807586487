<template>
  <div>
    <div class="d-flex align-center flex-wrap-reverse justify-space-between">
      <div class="d-flex" v-if="type == 'Trips'">
        <v-checkbox class="mx-2 mt-0" v-model="hideSplitTrips" label="Hide Split Trips" hide-details></v-checkbox>
        <v-checkbox
          class="mx-2 mt-0"
          v-model="hideThirdPartyPayments"
          label="Hide Third Party Payments"
          hide-details
        ></v-checkbox>
        <v-checkbox class="mx-2 mt-0" v-model="hideEstimates" label="Hide Estimates" hide-details></v-checkbox>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('import')" :disabled="disableImport || importing" :loading="importing">
        Import
      </v-btn>
    </div>
    <v-container fluid class="overflow-x-auto pa-0">
      <v-simple-table class="pa-0 grey lighten-4" fixed-header height="600px">
        <thead>
          <tr>
            <th class="text-no-wrap px-1 grey lighten-4">Row</th>
            <th
              class="text-no-wrap px-1 grey lighten-4"
              v-for="(field, i) in fields"
              v-show="showColumn(field)"
              :key="i"
              width="300"
            >
              {{ field.label }}
              <v-icon v-if="!!field.helper" @click="field.helper">mdi-help-box-multiple-outline</v-icon>
            </th>
          </tr>
          <tr class="mx-4 text-no-wrap grey lighten-4" v-if="!!rows.length">
            <th class="text-no-wrap grey lighten-4"></th>
            <th v-for="(field, i) in fields" v-show="showColumn(field)" :key="i" class="px-1 grey lighten-4">
              <v-select
                class="text-no-wrap"
                :value="field.mappedTo"
                v-model="field.mappedTo"
                :items="fileHeaders"
                item-text="label"
                item-value="value"
                dense
                hide-details
                outlined
                @change="mapValues(field)"
              ></v-select>
            </th>
          </tr>
        </thead>
        <tr
          v-for="(item, i) in items"
          :key="i"
          class="row-separator pa-0 rows"
          :class="{ 'highlighted-row': i === selectedRowIndex }"
          @click="selectRow(i)"
        >
          <td style="min-width: 40px">{{ i + 1 }}.</td>
          <td
            v-for="(field, j) in fields"
            :key="j"
            v-show="showColumn(field)"
            class="px-2 py-0"
            style="min-width: 100px"
          >
            <input
              v-if="field.type == 'text'"
              v-model="item[field.value]"
              class="regular-input"
              :class="{ 'error-border': item.errors.includes(field.value) }"
              @input="validate"
              :maxlength="batchEditField.value == 'state' ? 2 : undefined"
            />
            <input
              v-else-if="field.type == 'number'"
              v-model="item[field.value]"
              class="regular-input"
              :class="{ 'error-border': item.errors.includes(field.value) }"
              type="number"
              hideDetails
              @change="validate"
            />
            <input
              v-else-if="field.type == 'boolean'"
              class="checkbox"
              type="checkbox"
              :checked="item[field.value] === 1"
              @change="handleChangeCheckbox(field, item)"
              :class="{ 'error-border': item.errors.includes(field.value) }"
            />
            <input
              v-else-if="field.type == 'date'"
              v-model="item[field.value]"
              type="date"
              class="regular-input"
              :class="{ 'error-border': item.errors.includes(field.value) }"
              @input="validate"
            />
            <input
              v-else-if="field.type == 'time'"
              v-model="item[field.value]"
              type="time"
              class="regular-input"
              :class="{ 'error-border': item.errors.includes(field.value) }"
              @input="validate"
            />
            <v-select
              v-else-if="field.type.multiple"
              :class="item.errors.includes(field.value) ? 'error-outline' : 'default-select'"
              style="min-width: 100px"
              v-model="item[field.value]"
              :items="field.type.items"
              item-text="text"
              item-value="value"
              dense
              hide-details
              outlined
              multiple
              @change="validate"
            ></v-select>
            <select
              v-else
              :class="item.errors.includes(field.value) ? 'error-outline' : 'default-select'"
              style="min-width: 100px"
              v-model="item[field.value]"
              @change="validate"
            >
              <option v-for="(item, k) in field.type.items" :key="k" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
      </v-simple-table>
    </v-container>

    <v-row v-if="!!rows.length" dense class="mt-4">
      <v-col cols="2">
        <v-select
          class="text-no-wrap w-25"
          v-model="batchEditField"
          :items="fields"
          item-text="label"
          return-object
          label="Field"
          outlined
          dense
          @change="batchEditValue = null"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-if="batchEditField.type == 'text'"
          v-model="batchEditValue"
          :maxlength="batchEditField.value == 'state' ? 2 : undefined"
          dense
          @change="mapValues(batchEditField, batchEditValue)"
        ></v-text-field>
        <v-text-field
          v-else-if="batchEditField.type == 'number'"
          v-model="batchEditValue"
          dense
          type="number"
          @change="mapValues(batchEditField, batchEditValue)"
        ></v-text-field>
        <v-checkbox
          v-model="batchEditValue"
          v-else-if="batchEditField.type == 'boolean'"
          @change="mapValues(batchEditField, batchEditValue)"
        ></v-checkbox>
        <date-picker
          v-model="batchEditValue"
          v-else-if="batchEditField.type == 'date'"
          dense
          @input="mapValues(batchEditField, batchEditValue)"
        ></date-picker>
        <time-picker
          v-model="batchEditValue"
          v-else-if="batchEditField.type == 'time'"
          dense
          @input="mapValues(batchEditField, batchEditValue)"
        ></time-picker>
        <v-select
          v-else-if="batchEditField.type?.multiple"
          style="min-width: 100px"
          v-model="batchEditValue"
          :items="batchEditField.type ? batchEditField.type.items : []"
          item-text="text"
          item-value="value"
          dense
          hide-details
          outlined
          multiple
          @change="mapValues(batchEditField, batchEditValue)"
        ></v-select>
        <v-select
          v-model="batchEditValue"
          v-else
          :items="batchEditField.type ? batchEditField.type.items : []"
          item-text="name"
          item-value="id"
          dense
          outlined
          @change="mapValues(batchEditField, batchEditValue)"
        ></v-select>
      </v-col>
    </v-row>
    <v-dialog v-model="openStatusHelper" width="500">
      <v-card>
        <v-card-title class="text-h5"> Status </v-card-title>
        <v-card-text>
          <v-row dense v-for="(status, i) of fileStatuses" :key="i" class="mt-2">
            <v-col cols="6">{{ status.name }}</v-col>
            <v-col cols="6">
              <v-select
                :value="status.mappedTo"
                v-model="status.mappedTo"
                :items="statusMap"
                item-text="name"
                item-value="id"
                dense
                outlined
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeStatusHelper"> Done </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import { uniqBy } from 'lodash';

export default {
  label: 'ImportTable',
  inject: ['eventHub'],
  components: { DatePicker, TimePicker },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'Assignments',
    },
    statusMap: {
      type: Array,
      default: () => [],
    },
    fileStatuses: {
      type: Array,
      default: () => [],
    },
    importing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {
      batchEditField: {},
      batchEditValue: null,
      disableImport: true,
      selectedRowIndex: null,
      hideSplitTrips: false,
      hideThirdPartyPayments: false,
      hideEstimates: false,
      openStatusHelper: false,
    };
  },
  methods: {
    mapValues(field, value) {
      this.$emit('mapValues', field, value);
      this.validate();
    },
    validate() {
      let noErrors = true;
      for (let item of this.items) {
        item.errors = [];
        for (let field of this.fields)
          for (let val of field.validations) {
            if (!this[val](item[field.value])) {
              item.errors.push(field.value);
              noErrors = false;
            }
          }
      }
      this.disableImport = !noErrors;
    },
    handleChangeCheckbox(field, item) {
      this.$set(item, field.value, item[field.value] === 1 ? 0 : 1);
      this.validate();
    },
    validateRequired(value) {
      if (!value || (typeof value == 'string' && value.trim() === '')) return false;
      else return true;
    },
    validateInteger(value) {
      const intValue = Number.parseInt(value, 10);
      if (isNaN(intValue) || !Number.isInteger(intValue)) return false;
      else return true;
    },
    showColumn(field) {
      if (!field.hide) return true;
      else if (field.hide == 'split-trips') return !this.hideSplitTrips;
      else if (field.hide == 'third-party-payments') return !this.hideThirdPartyPayments;
      else if (field.hide == 'estimates') return !this.hideEstimates;
    },
    closeStatusHelper() {
      this.openStatusHelper = false;
      this.mapValues(this.fields.find((e) => e.value == 'status'));
    },
    convertToBoolean(value) {
      if (!value) return 0;
      const stringValue = String(value).trim().toLowerCase();
      if (stringValue === '0' || stringValue === 'false' || stringValue === 'no' || stringValue === 'n') {
        return 0;
      }
      return 1;
    },
    cleanText(rows) {
      for (let row of rows)
        for (let key of Object.keys(row)) {
          if (typeof row[key] === 'string') row[key] = row[key].trim();
          else if (typeof row[key] === 'object' && !!row[key])
            if (Array.isArray(row[key])) this.cleanText(row[key]);
            else this.cleanText([row[key]]);
        }
    },
    selectRow(index) {
      this.selectedRowIndex = index;
    },
  },
  watch: {
    rows() {
      this.validate();
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}
tr {
  margin-bottom: 2px;
}
td {
  /* border: 1px solid; */
  text-align: left;
  vertical-align: left;
}
.checkbox {
  display: flex;
  align-items: center !important;
}
.override-class {
  display: inline-block;
  width: 100px;
}
.regular-input {
  border-bottom: 1px solid #a5a3a3;
  padding: 2px;
  outline: none;
}
.error-border {
  border-bottom: 1px solid rgb(244, 67, 54) !important;
}
.error-outline {
  border: 1px solid rgb(244, 67, 54);
  border-radius: 4px;
}
.highlighted-row {
  background-color: #e0e0e0;
}
.rows:hover {
  background-color: #e0e0e0;
}
.rows {
  font-size: 14px;
}
.default-select {
  border: solid 1px rgb(145, 145, 145);
  border-radius: 4px;
}
</style>
