<template>
  <import-table
    :fileHeaders="fileHeaders"
    :rows="rows"
    :fields="fields"
    :items="destinations"
    :importing="importing"
    @mapValues="mapValues"
    @import="importDestinations"
    ref="importTableRef"
  ></import-table>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';

export default {
  name: 'DestinationTable',
  inject: ['eventHub'],
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fields() {
      return [
        {
          label: 'Name*',
          value: 'name',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Category',
          value: 'category',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Address*',
          value: 'address',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Address 2',
          value: 'address2',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'City*',
          value: 'city',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'State*',
          value: 'state',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Zip*',
          value: 'zip',
          type: 'number',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Phone',
          value: 'phone',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Email',
          value: 'email',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Cert Liab Exp Dt',
          value: 'liabilityExp',
          type: 'date',
          validations: [],
          mappedTo: null,
        },
      ];
    },
  },
  data() {
    return {
      destinations: [],
      importing: false,
    };
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.destinations.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        this.destinations[i][field.value] = rowVal;
      }
    },
    async importDestinations() {
      this.importing = true;
      const d = this.destinations.map((e) => ({
        name: e.name,
        category: e.category,
        address: {
          address: e.address,
          city: e.city,
          state: e.state,
          zip: e.zip,
        },
        liabilityExp: e.liabilityExp,
        phone: e.phone,
        email: e.email,
        active: true,
      }));
      if (d.length > 0) {
        try {
          this.$refs.importTableRef.cleanText(d);
          await importApi.importDestinations(d);
          this.$myalert.success('Destinations imported successfully');
        } catch (e) {
          this.$myalert.error(`Failed to import destinations: ${e.message}`);
        }
      } else {
        this.$myalert.error('No valid destinations found');
      }
      this.importing = false;
    },
  },
  watch: {
    rows() {
      this.destinations = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.destinations.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
};
</script>

<style scoped></style>
