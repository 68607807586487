<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="vehicles"
      :importing="importing"
      @mapValues="mapValues"
      @import="importVehicles"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { mapGetters } from 'vuex';

export default {
  name: 'VehicleTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      vehicles: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('location', ['locations', 'vehicleLocations', 'locationsByName']),
    fields() {
      return [
        {
          label: 'Name*',
          value: 'name',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Type*',
          value: 'type',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Location*',
          value: 'depotId',
          type: { items: this.allVehicleLocations },
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Capacity (Elementary)',
          value: 'capacityElem',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Capacity (Middle)',
          value: 'capacityMid',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Capacity (High)',
          value: 'capacityHigh',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Plate #',
          value: 'plateNum',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Model Year',
          value: 'modelYear',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Make',
          value: 'make',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'VIN',
          value: 'vin',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Engine Type',
          value: 'engineType',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Body Type',
          value: 'bodyType',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Wheelchair Lift',
          value: 'wheelchairLift',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Camera',
          value: 'camera',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: '# Wheelchair Slots',
          value: 'wheelchair',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: '# Safety Vests',
          value: 'numSafetyVest',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: '# Fold Fown Seats',
          value: 'numFoldDownSeat',
          type: 'number',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Travel Conditions',
          value: 'travelConditions',
          type: {
            items: [
              { id: 1, name: 'In County Only' },
              { id: 2, name: 'Unlimited Mileage' },
              { id: 3, name: '50 Mile Radius' },
            ],
          },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Description',
          value: 'description',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Active',
          value: 'active',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
      ];
    },
    allVehicleLocations() {
      return this.vehicleLocations.map((e) => {
        return { name: e.name, id: e.depotId };
      });
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.vehicles.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'depotId' && typeof rowVal == 'string') {
          if (this.locationsByName[rowVal.toLowerCase().trim()]) {
            this.vehicles[i][field.value] = this.locationsByName[rowVal.toLowerCase().trim()].stop.id;
          } else this.vehicles[i][field.value] = null;
        } else {
          this.vehicles[i][field.value] = rowVal;
        }

        if (field.type == 'boolean') {
          this.vehicles[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        }
      }
    },
    async importVehicles() {
      this.importing = true;
      const data = this.vehicles.map((e) => {
        return {
          name: e.name,
          type: e.type,
          depotId: e.depotId,
          capacityElem: Number(e.capacityElem) || 0,
          capacityMid: Number(e.capacityMid) || 0,
          capacityHigh: Number(e.capacityHigh) || 0,
          plateNum: e.plateNum,
          modelYear: e.modelYear,
          make: e.make,
          vin: e.vin,
          engineType: e.engineType,
          bodyType: e.bodyType,
          wheelchairLift: this.$refs.importTableRef.convertToBoolean(e.wheelchairLift),
          camera: this.$refs.importTableRef.convertToBoolean(e.camera),
          wheelchair: Number(e.wheelchair) || 0,
          numSafetyVest: Number(e.numSafetyVest) || 0,
          numFoldDownSeat: Number(e.numFoldDownSeat) || 0,
          travelConditions: e.travelConditions,
          description: e.description,
          active: this.$refs.importTableRef.convertToBoolean(e.active),
        };
      });
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importVehicles(data);
        this.$myalert.success('Vehicles imported successfully.');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeVehicles() {
      this.vehicles = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.vehicles.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeVehicles();
    },
  },
};
</script>

<style scoped></style>
