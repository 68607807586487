<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="drivers"
      :importing="importing"
      @mapValues="mapValues"
      @import="importDrivers"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { mapGetters } from 'vuex';
import { format, isValid } from 'date-fns';

export default {
  name: 'DriverTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drivers: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('location', ['zones', 'locations']),
    ...mapGetters('driver', ['designations']),
    ...mapGetters('vehicle', ['vehicles']),
    fields() {
      return [
        { label: 'ID', value: 'localId', type: 'text', validations: [], mappedTo: null },
        { label: 'First Name*', value: 'firstName', type: 'text', validations: ['validateRequired'], mappedTo: null },
        { label: 'Last Name*', value: 'lastName', type: 'text', validations: ['validateRequired'], mappedTo: null },
        { label: 'Email', value: 'email', type: 'text', validations: [], mappedTo: null },
        { label: 'Phone', value: 'phone', type: 'text', validations: [], mappedTo: null },
        { label: 'Text', value: 'canText', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Address', value: 'address', type: 'text', validations: [], mappedTo: null },
        { label: 'City', value: 'city', type: 'text', validations: [], mappedTo: null },
        { label: 'State', value: 'state', type: 'text', validations: [], mappedTo: null },
        { label: 'Zip', value: 'zip', type: 'text', validations: [], mappedTo: null },
        { label: 'SSN', value: 'ssn', type: 'text', validations: [], mappedTo: null },
        { label: 'Full-Time', value: 'fulltime', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Active', value: 'active', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Hire Date', value: 'hireAt', type: 'date', validations: [], mappedTo: null },
        { label: 'Birth Date', value: 'dob', type: 'date', validations: [], mappedTo: null },
        { label: 'Hourly Rate', value: 'payRate', type: 'number', validations: [], mappedTo: null },
        { label: 'OT Rate', value: 'otRate', type: 'number', validations: [], mappedTo: null },
        { label: 'Other Rate', value: 'otherRate', type: 'number', validations: [], mappedTo: null },
        { label: 'Prefer AM', value: 'prefAM', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer PM', value: 'prefPM', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer Weekends', value: 'prefWeekend', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer Weekdays', value: 'prefWeekday', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer Non-School Days', value: 'prefNonSchool', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer Overnights', value: 'prefOvernight', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Prefer Mid-Days', value: 'prefMidDay', type: 'boolean', validations: [], mappedTo: null },
        { label: 'Reg Hours/Week', value: 'regHoursWeek', type: 'number', validations: [], mappedTo: null },
        { label: 'Reg Hours/Day', value: 'regHoursDay', type: 'number', validations: [], mappedTo: null },
        {
          label: 'Zone',
          value: 'zone',
          // type: {
          //   items: this.zones.map((e) => {
          //     return { name: e, id: e };
          //   }),
          // },
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Designation',
          value: 'designation',
          // type: { items: this.designations },
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Location',
          value: 'baseLocationId',
          type: { items: this.locations },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Secondary Location',
          value: 'secondaryLocationId',
          type: { items: this.locations },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Assigned Vehicle',
          value: 'assignedVehicleId',
          type: { items: this.vehicles },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Vehicle Preference',
          value: 'vehiclePreference',
          type: {
            items: [
              { name: 'No Preference', id: 0 },
              { name: 'Regular Only', id: 1 },
              { name: 'Special Needs Only', id: 2 },
              { name: 'Either (Prefer Regular)', id: 3 },
              { name: 'Either (Prefer Special Needs)', id: 4 },
            ],
          },
          validations: [],
          mappedTo: null,
        },
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.drivers.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'payRate' || field.value == 'otRate' || field.value == 'otherRate') {
          this.drivers[i][field.value] = Number(rowVal.replace(/[^0-9.,]+/, ''));
        } else this.drivers[i][field.value] = rowVal;

        if (field.type == 'boolean') {
          this.drivers[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        } else if (field.type == 'date')
          this.drivers[i][field.value] =
            rowVal && isValid(new Date(rowVal)) ? format(new Date(rowVal), 'yyyy-MM-dd') : null;
      }
    },
    async importDrivers() {
      this.importing = true;
      const data = this.drivers.map((e) => {
        const obj = {
          localId: e.localId,
          firstName: e.firstName.trim(),
          lastName: e.lastName.trim(),
          email: e.email,
          phone: e.phone,
          SSN: e.ssn,
          fulltime: e.fulltime,
          active: e.active,
          hireAt: e.hireAt,
          dob: e.dob,
          payRate: e.payRate,
          otRate: e.otRate,
          otherRate: e.otherRate,
          prefAM: e.prefAM,
          prefPM: e.prefPM,
          prefWeekend: e.prefWeekend,
          prefWeekday: e.prefWeekday,
          prefNonSchool: e.prefNonSchool,
          prefOvernight: e.prefOvernight,
          prefMidDay: e.prefMidDay,
          regHoursWeek: e.regHoursWeek,
          regHoursDay: e.regHoursDay,
          zone: e.zone,
          designation: e.designation,
          baseLocationId: e.baseLocationId,
          secondaryLocationId: e.secondaryLocationId,
          assignedVehicleId: e.assignedVehicleId,
          vehiclePreference: e.vehiclePreference,
          addresses: e.address
            ? [
                {
                  name: 'Home',
                  isPrimary: 1,
                  isMailing: 0,
                  hidden: 0,
                  address: e.address,
                  address2: e.address2 || '',
                  city: e.city,
                  state: e.state,
                  zip: e.zip,
                },
              ]
            : [],
          contacts:
            e.email || e.phone
              ? [
                  {
                    emails: [{ email: e.email }],
                    name: 'Self',
                    isPrimary: 1,
                    phones: [{ phone: e.phone, canText: e.canText }],
                  },
                ]
              : [],
        };
        return obj;
      });
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importDrivers(data);
        this.$myalert.success('Drivers imported successfully');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeDrivers() {
      this.drivers = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.drivers.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeDrivers();
    },
  },
};
</script>

<style scoped></style>
