<template>
  <div>
    <div class="py-8 mx-16 main">
      <v-row class="px-2">
        <v-col class="pa-0 grey lighten-4">
          <div class="text-h5 mb-4">IMPORT CSV</div>
        </v-col>
      </v-row>
      <v-row class="mb-8 px-2">
        <v-col cols="12" class="pa-0 grey lighten-4">
          <label for="data-type-select" class="select-label">Choose data type you want to import</label>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            class="select mb-8"
            v-model="selectedOption"
            :items="options.map((f) => f.label).sort()"
            item-text="label"
            item-value="label"
            outlined
            id="data-type-select"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col class="pa-0">
          <v-card class="grey lighten-4">
            <v-card-title class="font-weight-medium text-uppercase">
              <v-icon v-if="populateOptions.icon" class="mr-2 black--text">{{ populateOptions.icon }}</v-icon>
              {{ selectedOption }}
            </v-card-title>
            <v-card-text>
              <p>Select csv file from disk, or drag and drop the file here</p>
              <p><v-icon color="#FFC107" class="mr-2">mdi-alert</v-icon>{{ populateOptions.update }}</p>
            </v-card-text>
            <v-card-actions>
              <template v-if="!importing">
                <v-file-input @change="upload" :value="fileValue" placeholder="Upload File"></v-file-input>
              </template>
              <v-progress-circular
                v-else
                :size="50"
                color="primary"
                indeterminate
                class="progress"
              ></v-progress-circular>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col class="pa-0"> </v-col>
      </v-row>
    </div>
    <template>
      <div class="loader-container" v-if="showLoader">
        <v-progress-circular :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
      </div>
    </template>

    <v-card class="px-6 py-1 grey lighten-4">
      <assignment-table
        v-if="selectedOption == 'Assignments'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></assignment-table>

      <budget-code-table
        v-if="selectedOption == 'Budget Codes'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></budget-code-table>

      <destination-table
        v-else-if="selectedOption == 'Destinations'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></destination-table>

      <driver-table v-else-if="selectedOption == 'Drivers'" :fileHeaders="csvHeaders" :rows="lines"></driver-table>

      <funding-source-table
        v-else-if="selectedOption == 'Funding Sources'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></funding-source-table>

      <location-table
        v-else-if="selectedOption == 'Locations'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></location-table>

      <special-date-table
        v-else-if="selectedOption == 'Special Dates'"
        :fileHeaders="csvHeaders"
        :rows="lines"
      ></special-date-table>

      <trip-table v-else-if="selectedOption == 'Trips'" :fileHeaders="csvHeaders" :rows="lines"></trip-table>

      <user-table v-else-if="selectedOption == 'Users'" :fileHeaders="csvHeaders" :rows="lines"></user-table>

      <vehicle-table v-else-if="selectedOption == 'Vehicles'" :fileHeaders="csvHeaders" :rows="lines"></vehicle-table>
    </v-card>
  </div>
</template>

<script>
import Papa from 'papaparse';
import AssignmentTable from './AssignmentTable.vue';
import BudgetCodeTable from './BudgetCodeTable.vue';
import DestinationTable from './DestinationTable.vue';
import DriverTable from './DriverTable.vue';
import FundingSourceTable from './FundingSourceTable.vue';
import LocationTable from './LocationTable.vue';
import SpecialDateTable from './SpecialDateTable.vue';
import TripTable from './TripTable.vue';
import UserTable from './UserTable.vue';
import VehicleTable from './VehicleTable.vue';

export default {
  name: 'Import',
  components: {
    AssignmentTable,
    BudgetCodeTable,
    DestinationTable,
    DriverTable,
    FundingSourceTable,
    LocationTable,
    SpecialDateTable,
    TripTable,
    UserTable,
    VehicleTable,
  },
  data() {
    return {
      showLoader: false,
      headers: [
        { text: 'Required Field', value: 'field' },
        { text: 'Mapped To', value: 'mapping' },
      ],
      disableImport: false,
      csvHeaders: [],
      fileValue: null,
      lines: [],
      selectedOption: 'Assignments',
      type: {},
      importing: false,
      options: [
        // {
        //   label: 'Approved Charters',
        //   icon: 'mdi-bus',
        //   update: '',
        // },
        {
          label: 'Assignments',
          icon: 'mdi-bus-marker',
          update:
            "If the row's Trip ID matches an existing entry along with the Driver or Vehicle, that row will update the existing entry in the database",
        },
        {
          label: 'Budget Codes',
          icon: 'mdi-numeric',
          update:
            "If the row's Name matches an existing entry, that row will update the existing entry in the database",
        },
        // {
        //   label: 'Contractors',
        //   icon: 'mdi-badge-account-alert-outline',
        //   update: '',
        // },
        {
          label: 'Destinations',
          icon: 'mdi-map-marker-distance',
          update:
            "If the row's Name matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Drivers',
          icon: 'mdi-card-account-details-outline',
          update:
            "If the row's Local ID matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Funding Sources',
          icon: 'mdi-cash-sync',
          update:
            "If the row's Name matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Locations',
          icon: 'mdi-map-marker',
          update:
            "If the row's Name or Code matches an existing entry, that row will update the existing entry in the database",
        },
        // {
        //   label: 'Rental / Dealership Agencies',
        //   icon: 'mdi-car-clock',
        //   update: '',
        // },
        // {
        //   label: 'Special Indicators',
        //   icon: 'mdi-folder-star',
        //   update: '',
        // },
        // {
        //   label: 'Travel Needs',
        //   icon: 'mdi-bag-checked',
        //   update: '',
        // },
        // {
        //   label: 'Trip Events',
        //   icon: 'mdi-train-car',
        //   update: '',
        // },
        // {
        //   label: 'Trip Types',
        //   icon: 'mdi-car-info',
        //   update: '',
        // },
        // {
        //   label: 'Vehicle Types',
        //   icon: 'mdi-bus-alert',
        //   update: '',
        // },
        {
          label: 'Special Dates',
          icon: 'mdi-calendar-range',
          update:
            "If the row's Name matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Trips',
          icon: 'mdi-bus-marker',
          update:
            "If the row's Local ID matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Users',
          icon: 'mdi-account',
          update:
            "If the row's Email matches an existing entry, that row will update the existing entry in the database",
        },
        {
          label: 'Vehicles',
          icon: 'mdi-car-multiple',
          update:
            "If the row's Name matches an existing entry, that row will update the existing entry in the database",
        },
      ],
    };
  },
  computed: {
    populateOptions() {
      return this.options.find((field) => field.label === this.selectedOption);
    },
  },
  methods: {
    async upload(file) {
      if (!file) return;

      this.disableImport = true;
      this.showLoader = true;
      Papa.parse(file, {
        complete: (result) => {
          this.showLoader = true;
          const columnKeys = result.data[0];
          this.createColumnKeys(columnKeys);

          result.data.shift();
          this.lines = result.data.filter((e) => e.length > 1);
          this.showLoader = false;
        },
      });
    },
    createColumnKeys(headers) {
      const keys = [];
      let i = 0;
      headers.forEach((header) => {
        keys.push({ label: header, value: i });
        i++;
      });
      this.csvHeaders = keys;
    },
  },
};
</script>

<style scoped>
.select {
  width: 20% !important;
  padding: 2px !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 2px !important;
  height: 1rem !important;
}
.main {
  width: 80% !important;
  margin: auto !important;
}
.file-input-label {
  display: inline-block;
  background-color: rgb(96, 125, 139);
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.file-input {
  display: none;
}
.file-input-button {
  margin-right: 8px;
}
h4 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
}
span {
  padding: 2px !important;
}
label {
  font-size: 0.9rem !important;
}
.select-label {
  font-size: 0.9rem !important;
  color: #6e6e6e !important;
}
.tag {
  display: inline-block !important;
  line-height: 14px !important;
  margin-top: -2px !important;
  margin-bottom: -2px !important;
  margin-right: 3px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  background-color: #cac9c9 !important;
  border-radius: 3px !important;
  color: #000000 !important;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
