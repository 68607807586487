<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="locations"
      :importing="importing"
      @mapValues="mapValues"
      @import="importLocations"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { mapGetters } from 'vuex';

export default {
  name: 'LocationTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locations: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('location', ['zones']),
    fields() {
      return [
        {
          label: 'Name*',
          value: 'name',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Address*',
          value: 'address',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'City*',
          value: 'city',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'State*',
          value: 'state',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Zip Code*',
          value: 'zip',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Abbreviation*',
          value: 'abbr',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Code*',
          value: 'code',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Zone',
          value: 'zone',
          type: {
            items: this.zones.map((e) => {
              return { name: e, id: e };
            }),
          },
          validations: [],
          mappedTo: null,
        },
        { label: 'Active', value: 'active', type: 'boolean', validations: [], mappedTo: null },
        {
          label: 'Incl Common Dest',
          value: 'inclCommonDest',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Is School',
          value: 'isSchool',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        { value: 'isVehicleLocation', label: 'Is Vehicle Location', type: 'boolean', validations: [], mappedTo: null },
        // {
        //   label: 'Site Adm 1',
        //   value: 'siteAdm1',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Site Adm 2',
        //   value: 'siteAdm2',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Site Adm 3',
        //   value: 'siteAdm3',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Site Adm 4',
        //   value: 'siteAdm4',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Chld Nut',
        //   value: 'chldNut',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Chld Nut 2',
        //   value: 'chldNut2',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'RespPmt',
        //   value: 'respPmt',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Owner',
        //   value: 'owner',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Owner Spec Needs',
        //   value: 'ownerSpecNeeds',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Nurse',
        //   value: 'nurse',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Nurse2',
        //   value: 'nurse2',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Loc 1',
        //   value: 'loc1',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Loc 2',
        //   value: 'loc2',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Loc 3',
        //   value: 'loc3',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Loc 4',
        //   value: 'loc4',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },

        // {
        //   label: 'Status',
        //   value: 'status',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
        // {
        //   label: 'Destination',
        //   value: 'destination',
        //   type: 'text',
        //   validations: [],
        //   mappedTo: null,
        // },
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.locations.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.type == 'boolean') {
          this.locations[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        } else if (field.value == 'state') {
          this.locations[i][field.value] = rowVal.toUpperCase();
        } else {
          this.locations[i][field.value] = rowVal;
        }
      }
    },
    async importLocations() {
      this.importing = true;
      const data = this.locations.map((e) => {
        return {
          code: e.code,
          name: e.name,
          address: {
            address: e.address,
            city: e.city,
            state: e.state,
            zip: e.zip,
          },
          abbr: e.abbr,
          zone: e.zone,
          active: this.$refs.importTableRef.convertToBoolean(e.active),
          isSchool:
            this.$refs.importTableRef.convertToBoolean(e.isSchool) ||
            (!this.$refs.importTableRef.convertToBoolean(e.isSchool) &&
              !this.$refs.importTableRef.convertToBoolean(e.isVehicleLocation)),
          isVehicleLocation: this.$refs.importTableRef.convertToBoolean(e.isVehicleLocation),
          inclCommonDest: this.$refs.importTableRef.convertToBoolean(e.inclCommonDest),
          roles: {},
          stop: null,
          vehicleOrder: null,
        };
      });
      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importLocations(data);
        this.$myalert.success('Locations imported successfully');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeLocations() {
      this.locations = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.locations.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeLocations();
    },
  },
};
</script>

<style scoped></style>
