<template>
  <div>
    <import-table
      :fileHeaders="fileHeaders"
      :rows="rows"
      :fields="fields"
      :items="fundingSources"
      :importing="importing"
      @mapValues="mapValues"
      @import="importFundingSources"
      ref="importTableRef"
    ></import-table>
  </div>
</template>

<script>
import ImportTable from './ImportTable.vue';
import importApi from '@/apis/import';
import { format, isValid } from 'date-fns';
import { mapGetters } from 'vuex';
import { fundingSourceMap } from '@/util/enums';

export default {
  name: 'FundingSourceTable',
  components: {
    ImportTable,
  },
  props: {
    fileHeaders: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fundingSources: [],
      importing: false,
    };
  },
  computed: {
    ...mapGetters('user', ['users', 'usersById', 'usersByEmail']),
    ...mapGetters('location', ['locations', 'locationsByName']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesByName']),
    fields() {
      return [
        {
          label: 'Name*',
          value: 'name',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Type*',
          value: 'type',
          type: {
            items: [
              fundingSourceMap.type.singleBasedOnLoc,
              fundingSourceMap.type.singleAllotment,
              fundingSourceMap.type.multiple,
              fundingSourceMap.type.editable,
            ],
          },
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Budget Code*',
          value: 'budgetCode',
          type: 'text',
          validations: ['validateRequired'],
          mappedTo: null,
        },
        {
          label: 'Budget Code Editable',
          value: 'codeIsEditable',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Allotments Used',
          value: 'allotmentsUsed',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Description',
          value: 'description',
          type: 'text',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Approver',
          value: 'approverEmail',
          type: {
            items: this.users.map((e) => ({ name: `${e.displayName} (${e.email})`, id: e.email })),
          },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Payment Manager',
          value: 'payerEmail',
          type: {
            items: this.users.map((e) => ({ name: `${e.displayName} (${e.email})`, id: e.email })),
          },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Notification of Use',
          value: 'notifyEmail',
          type: {
            items: this.users.map((e) => ({ name: `${e.displayName} (${e.email})`, id: e.email })),
          },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Include on School Finance',
          value: 'incOnFinance',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Location Specific',
          value: 'locationSpecific',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Locations',
          value: 'locations',
          type: { items: this.locations.map((e) => ({ text: e.name, value: e.id })), multiple: true },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Display on Trip Types',
          value: 'displayOnTripTypes',
          type: { items: this.tripTypes.map((e) => ({ text: e.name, value: e.id })), multiple: true },
          validations: [],
          mappedTo: null,
        },
        {
          label: 'Active',
          value: 'active',
          type: 'boolean',
          validations: [],
          mappedTo: null,
        },
      ];
    },
  },
  methods: {
    mapValues(field, value) {
      for (let i = 0; i < this.fundingSources.length; i++) {
        const rowVal = value || this.rows[i][field.mappedTo] || null;
        if (field.value == 'type' && typeof rowVal == 'string' && !isNaN(rowVal)) {
          this.fundingSources[i][field.value] = Number(rowVal);
        } else if (field.value == 'locations' && typeof rowVal == 'string') {
          const locations = [];
          for (let l of rowVal.split(';'))
            if (this.locationsByName[l.toLowerCase().trim()])
              locations.push(this.locationsByName[l.toLowerCase().trim()].id);
          this.fundingSources[i][field.value] = locations;
        } else if (field.value == 'displayOnTripTypes' && typeof rowVal == 'string') {
          const tripTypes = [];
          for (let l of rowVal.split(';'))
            if (this.tripTypesByName[l.toLowerCase().trim()])
              tripTypes.push(this.tripTypesByName[l.toLowerCase().trim()].id);
          this.fundingSources[i][field.value] = tripTypes;
        } else {
          this.fundingSources[i][field.value] = rowVal;
        }

        if (field.type == 'date' && !value) {
          const dt = this.fundingSources[i][field.value];
          this.fundingSources[i][field.value] = dt && isValid(new Date(dt)) ? format(new Date(dt), 'yyyy-MM-dd') : null;
        } else if (field.type == 'boolean') {
          this.fundingSources[i][field.value] = this.$refs.importTableRef.convertToBoolean(rowVal);
        }
      }
    },
    async importFundingSources() {
      this.importing = true;
      const data = this.fundingSources.map((e) => {
        return {
          name: e.name,
          type: e.type,
          active: e.active,
          allotmentsUsed: e.allotmentsUsed,
          budgetCode: e.budgetCode,
          codeIsEditable: e.codeIsEditable,
          description: e.description,
          approverId: e.approverEmail ? this.usersByEmail[e.approverEmail].id : null,
          approverEmail: e.approverEmail || null,
          payerId: e.payerEmail ? this.usersByEmail[e.payerEmail].id : null,
          payerEmail: e.payerEmail || null,
          notifyEmail: e.notifyEmail,
          incOnFinance: e.incOnFinance,
          locationSpecific: e.locationSpecific,
          fundSpecific: e.fundSpecific,
          locations: e.locations,
          displayOnTripTypes: e.displayOnTripTypes,
        };
      });

      try {
        this.$refs.importTableRef.cleanText(data);
        await importApi.importFundingSources(data);
        this.$myalert.success('FundingSources imported successfully.');
      } catch (e) {
        this.$myalert.error(e.message);
      }
      this.importing = false;
    },
    initializeFundingSources() {
      this.fundingSources = Array.from({ length: this.rows.length }, () =>
        Object.fromEntries(this.fields.map((e) => e.value).map((key) => [key, null]))
      );
      this.fundingSources.forEach((e) => (e.errors = []));
      for (let i = 0; i < this.fields.length; i++) {
        const match = this.fileHeaders.find((e) => e.label == this.fields[i].label.replace(/\*/g, ''));
        if (match) {
          this.fields[i].mappedTo = match.value;
          this.mapValues(this.fields[i]);
        }
      }
    },
  },
  watch: {
    rows() {
      this.initializeFundingSources();
    },
  },
};
</script>

<style scoped></style>
